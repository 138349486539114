<template>
  <div id="app">
    <nav-bar />
    <div class="main-body">
      <router-view/>
    </div>
    <Footer />
  </div>
  
  
</template>

<script>
  import NavBar from '@/components/navigation/NavBar.vue';
  import Footer from '@/components/navigation/Footer.vue';

  export default {
    components: {
      NavBar,
      Footer
    }
  }
</script>

<style lang="scss" scoped>
    #app {
        font-family: 'Oswald', sans-serif;
        font-style: italic;
        background-color: white;
    }
    .main-body {
      padding-top: 100px;
      max-width: 1500px;
      margin-left: auto;
      margin-right: auto;
    }

    @media (max-width: 767px) {
      .main-body {
        padding-top: 75px;
      }
    }
</style>
