<template>
    <div class="lista-natal">
        <img class="banner-detailimg2-natal" :src="require(`@/assets/images/banner_detail2.webp`)" />
        <h1 class="title-natal">Fim de Ano Dalena</h1>
        <div class="produtos-natal">
            <div class="spinner-grow" role="status" v-if="loading"></div>
            <div class="col-6 col-sm-4" v-for="produto in list" :key="produto.prod_id">
                <a v-bind:href="`/produto/${rightID(produto.prod_id)}`">
                    <div class="card-natal bg-transparen h-100">
                        <div class="foto-natal">
                            <img v-bind:src="require(`@/assets/images/produtos/natal_nb/${produto.prod_id}.webp`)" v-bind:alt="`${produto.name}`" class="card-img-top img-fluid">
                        </div>
                        <div class="card-body-natal">
                            <p class="card-title-natal">{{ produto.name }}</p>
                            <!--
                            <div class="stars-natal" >
                                <div class="star-rating-natal">
                                    <span v-for="star in 5" :key="star"><font-awesome-icon icon="fa-regular fa-star" /></span>
                                    <div class="star-rating__current-natal" :style="{width: ((produto.rating/5)*100)+'%'}">
                                        <span v-for="star in 5" :key="star" ><font-awesome-icon icon="fa-solid fa-star"  id="solidStar"/></span>
                                    </div>
                                </div>
                                <span class="votes-natal"><small>{{ produto.rating.toFixed(1) }}</small></span>
                            </div>
                            -->
                            <p class="card-text-natal2"><span v-if="produto.cat!='e'">22CM - </span>R${{ produto.preco_g }}<span v-if="produto.cat!='e'"> | 16CM - R${{ produto.preco_p }}</span></p>
                            <p class="card-text-natal">{{ limitText(produto.desc) }}</p>
                        </div>
                    </div>
                </a>
            </div>
        </div>
        <a type="button" class="btn btn-md" target=”_blank” role="button" id="reserve" 
           href="https://api.whatsapp.com/send?phone=5581992984004&text=Ol%C3%A1%20!%20Vi%20o%20card%C3%A1pio%20de%20Natal,%20gostaria%20de%20fazer%20uma%20encomenda%20!">
            Reserve Agora!
        </a>
        <!--<font-awesome-icon class="whatsapp" :icon="['fab', 'whatsapp']" />-->
    </div>
</template>

<script>
import { useHead } from '@unhead/vue'

export default {
    name: "Natal24",
    setup() {
        useHead({
            title: 'Tortas e Bolos'
        })
    },
    data() {
        return {
            list: [],
            tipo: this.$route.params.tipo,
          //  title: '',
            loading: true
        }
    },
    created() {
        fetch(`https://6zk5h46ao2tkvb3emi4xosrcju0fvayv.lambda-url.us-east-2.on.aws/api/produtos/natal`)
        .then(async response => {
            const data = await response.json()

            if (!response.ok) {
                // get error message from body or default to response status
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            } 

            this.list = data;
            this.loading = false
        })
        .catch(error => {
            console.error('An error ocurred: ', error);
        })

       /* this.title = this.tipo.toLowerCase().replace(/\b[a-z]/g, function(letter) {
                            return letter.toUpperCase();
                        });*/
    },
    methods: {
        limitText(desc) {
            if (desc.length > 20) {
                    desc = desc.substring(0, 58) + ' ...';
                }
            return desc 
        },
        login () {
            this.$gtag.event('login', { method: 'Google' })
        },
        rightID(id) {
            return id.replace("natal_","")
        }
    }
}
</script>

<style lang="scss">
    @font-face {
        font-family: 'Lora';
        src: local("Lora"),
        url(@/fonts/Lora-Italic.ttf) format("truetype");
    }
    .lista-natal {
        background-image: url('@/assets/images/BGNatal_Desktop.png');
        background-size: contain;
       // background-color: #EDE2D1;
    }
    .spinner-grow {
        color: #77321C;
        width: 6rem;
        height: 6rem;
    }
    .banner-detailimg2-natal {
        display: block;
        width: 23%;
        position: absolute;
        top: 100px;
        left: 0;
        margin-left: 40px;
    }
    .title-natal {
        padding: 60px 0 100px 0;
        text-align: center;
        font-family: 'Lora';
        color: #77321C;
        font-size: 32px;
        font-style: italic;
        // background-color: #EDE2D1;
    }
    .produtos-natal {
        margin: 0 30px 0 30px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }


    //   CARD 

    a {
        text-decoration: none;
    }
    .card-natal {
        margin: 0 10px 0 10px;
        background-color: transparent;
        border: none;
    }
    .card-body-natal {
        font-family: 'Lora';
        align-self: center;
        text-align: left;
        line-height: 1.8;
        padding-top: 8px;
        color: #77321C;
    }
    .card-title-natal {
        font-family: 'Lora';
        font-size: 22px;
        font-weight: bold;
        margin: 0;
    }

    .card-text-natal2 {
        font-family: 'Lora';
        font-size: 16px;
        line-height: 1.3;
        margin-bottom: 0;
    }
    
    .foto-natal {
        background-color: #EDE2D1;
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
    }

    .produtos-natal>div{
        margin-bottom: 15px;
    }


    //   ESTRELAS 

    .star-rating-natal {
        display: inline-block;
        color: #D0862E;
        position: relative;
        font-size: 10px;
        vertical-align: middle;
    }

    .star-rating-natal .star-rating__current-natal {
        position: absolute;
        top: 0;
        overflow: hidden;
        white-space: nowrap;
    }

    .fa-star {
        margin-right: 3px;
    }
    .votes-natal {
        font-family: 'Lora';
        font-size: 12px;
        color: #D0862E;
        margin-left: 2px;
    }

    /*.whatsapp {
        color: #008000;
        font-size: 60px;
        padding: 0 3px 0 3px;
    }*/

    #reserve {
        background-color: #008000;
        color: white;
        font-family: 'Lora';
        width: 160px;
        border-radius: 40px;
        position: sticky;
        margin-right: 30px;
        bottom: 5px;
        float: right;
        --bs-btn-font-size: 1.2rem;
        --bs-btn-font-weight: bold;
    }


    //  MOBILE

    @media (max-width: 767px) {
        .lista-natal {
            background-image: url('@/assets/images/BGNatal_Mobile.png');
        }
        .banner-detailimg2-natal {
            display: none;
        }
        .title-natal {
            padding: 20px 0 30px 0;
        }
        .produtos-natal {
            margin: 0 8px 0 0;
        }
        .produtos-natal>div{
            padding: 0 0 0 8px;
        }
        .card-natal {
            margin: 0;
        }

        .card-body-natal {
            padding: 0 0 0 5px;
        }

        .card-title-natal {
            white-space: wrap;
            font-size: 16px;
            line-height: 1.3;
        }
        .card-text-natal {
            font-size: 12px;
            line-height: 1.3;
            margin-top: -3px;
        }
        .card-text-natal2 {
            font-size: 12px;
            line-height: 1.3;
        }
        .card-text-natal2 {
            font-size: 12px;
            margin-bottom: 5px;
        }

        .stars-natal{
            white-space: nowrap;
            margin-top: -10px;
        }

        .whatsapp {
            margin-right: 10px;
        }
    }
</style>