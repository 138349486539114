<template>
  <div class="dalena">
    <div class="banner">
      <img class="banner-detailimg1" :src="require(`@/assets/images/banner_detail1.webp`)" />
      <h1 id="banner-title">Dalena</h1>
      <img class="banner-foto" :src="require(`@/assets/images/foto.webp`)" />
    </div>
    <img class="banner-detailimg2" :src="require(`@/assets/images/banner_detail2.webp`)" />
    <div class="title">
        <h1 id="title1">Uma história de sucesso</h1>
        <h1 id="title2">e muito amor</h1>
    </div>
    <div id="texto">
        <p>
          Tudo começou com uma receita única de torta alemã, que ganhou vida pelas mãos da empresária Madalena Souza. 
          O que era um doce caseiro, compartilhado informalmente em sua própria residência, evoluiu para algo muito maior. 
          Em 1998, diante da crescente demanda e da paixão pela confeitaria, Madalena inaugurou a primeira loja no bairro de 
          Boa Viagem. Esse foi o ponto de partida para uma jornada doce que não conheceu limites.
        </p>
        <p>
          O aroma irresistível e os sabores únicos das criações de Madalena não podiam ficar confinados a um único local. A 
          Dalena logo abriu segunda loja na Zona Norte e, em seguida, expandiu para os shoppings da cidade.Atualmente, temos o 
          orgulho de contar com quatro lojas próprias que personificam o requinte e a qualidade que são nossa marca registrada.
        </p>
        <p>
          Cada produto que sai de nossas cozinhas é resultado de dedicação e paixão. Na Dalena, cada receita é cuidadosamente 
          equilibrada, passando por rigorosos testes de qualidade até atingir o ponto de perfeição. Isso se tornou sinônimo de 
          nossa marca, um compromisso inabalável com a excelência.
        </p>
        <p>
          Nossa especialidade incontestável são as tortas, que agora somam mais de 50 criações únicas. A estrela da casa, a torta 
          alemã, que foi nossa pioneira, hoje brilha em uma variedade de sabores, incluindo morango, chocolate branco, 
          nozes, amendoim e uma versão sem adição de açúcar. Essa capacidade constante de reinventar-se tem sido recompensada 
          com vários prêmios, incluindo nove consecutivos de Melhor Doceria, concedidos por uma renomada revista.
        </p>
        <p>
          Hoje, somos mais do que uma confeitaria. Somos uma tradição que oferece um lar doce lar para todos os momentos da vida. 
          Junte-se a nós em nossa jornada de sabor, qualidade e hospitalidade que perdura há mais de 20 anos. Venha fazer 
          parte da nossa história, onde cada doce é uma obra de arte e cada encontro é um abraço caloroso. Bem-vindo à Dalena, 
          onde os momentos doces se transformam em memórias inesquecíveis.
        </p>
    </div>
    <div class="banner2">
        <img class="separator" id="banner1" :src="require(`@/assets/images/banner2.webp`)" />
        <img class="separator" id="banner2" :src="require(`@/assets/images/banner2_2.webp`)" />
      </div>
      <div class="title2">
        <h1>Conheça nosso cardápio</h1>
      </div>
      <div class="know-more">
        <div class="col-12 col-md-4">
            <a href="/cardapio/torta">
              <div class="card border-light bg-transparen h-100">
                  <img v-bind:src="require(`@/assets/images/maistorta.webp`)" class="card-img-top img-fluid" alt="Tortas"> 
                  <div class="card-body">
                    <h5 class="card-title">TORTAS</h5>
                  </div>
              </div>
            </a>
        </div>
        <div class="col-12 col-md-4 center_know">
          <a href="/cardapio/bolo">
            <div class="card border-light bg-transparen h-100">
                <img v-bind:src="require(`@/assets/images/maisbolo.webp`)" class="card-img-top img-fluid" alt="Bolos"> 
                <div class="card-body">
                  <h5 class="card-title">BOLOS</h5>
                </div>
            </div> 
          </a>
        </div>
      </div>
      <div class="to_top">
        <a href="#" role="button" class="btn btn-md"><font-awesome-icon icon="fa-solid fa-angle-up" size="3x" /></a>
      </div>
  </div>

</template>

<script>
import { useHead } from '@unhead/vue'

export default {
  name: 'Dalena',
  setup() {
    useHead({
      title: 'A Dalena'
    })
  },
  methods: {
      login () {
        this.$gtag.event('login', { method: 'Google' })
      }
    }
}
</script>

<style lang="scss" scoped>
    @font-face {
        font-family: 'AutumnChant';
        src: local("Autumn Chant"),
        url(@/fonts/Autumn-Chant.otf) format("truetype");
    }
    @font-face {
        font-family: 'GoldplayAlt';
        src: local("GoldplayAlt"),
        url(@/fonts/GoldplayAlt-Medium.ttf) format("truetype");
    }
    @font-face {
        font-family: 'Lora';
        src: local("Lora"),
        url(@/fonts/Lora-Italic.ttf) format("truetype");
    }
  #app {
      background-color: white !important;
  }
  .banner-detailimg1 {
    width: 18%;
    position: absolute;
  }
  .banner-foto {
    width: 100%;
    padding: 0 40px 0 40px;
  }
  #banner-title {
    font-family: 'AutumnChant';
    color: #EDE2D1;   
    position: absolute;
    top: 300px;
    left: 210px;
    font-size: 68px;
  }
  .banner-detailimg2 {
    width: 23%;
    position: absolute;
    right: 0;
    margin-right: 40px;
  }
  .title {
    margin: 85px 0 85px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #title1 {
    font-family: 'Lora';
    color: #77321C;
  }

  #title2 {
    font-family: 'AutumnChant';
    color: #CE842D;
    line-height: 8px;
    font-size: 45px;
  }

  #texto {
    margin: 0 120px 50px 120px;
    column-count: 2;
    column-gap: 70px;
    font-family: 'Lora';
    text-align: justify;
    color: #77321C;
  }

  .separator {
    width: 100%;
  }

  #banner1 {
    display: block;
  }
  
  #banner2 {
    display: none;
  }

  .title2>h1 {
    font-family: 'Lora';
    color: #77321C;
    margin: 25px 0 25px 0;
    font-size: 32px;
    font-style: italic;
    text-align: center;
  }
  a {
    text-decoration: none;
    color: black;
  }
  .card {
    margin: 5px 20px 5px 20px;
    background-color: transparent;
    border: none;
  }
  .card-body {
    align-self: center;
    text-align: center;
    line-height: 1.8;
    color: #77321C;
  }

  .card-text {
    font-size: 14px;
    font-family: 'Lora';
    font-weight: bold;
  }

  .card-title {
    font-size: 18px;
    font-family: 'Lora';
    font-weight: bold;
  }
  .know-more {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    margin-bottom: 20px;
    padding: 0 80px 10px 80px;
  }

  .center_know{
    border-left: 1px solid rgba(119, 50, 28, 0.5);
  }

  .know-more>div {
    padding: 0 50px 0 50px;
  }
  
  .know-more>div>a>.card {
    padding-top: 10px;
  }

  .to_top {
    display: none;
  }


  @media (max-width: 767px) {

    #banner-title {
      top: 126px;
      left: 52px;
      font-size: 23px;
    }
    .banner-foto {
      padding: 0 26px 0 26px;
    }
    .banner-detailimg2 {
      margin-right: 26px;
    }

    .title {
      margin: 55px 0 30px 0;
    }

    #title1 {
      font-size: 20px;
    }

    #title2 {
      font-size: 24px;
      line-height: 2px;
    }
    #texto {
      margin: 0 32px 20px 32px;
      column-count: 1;
    }
    #banner1 {
      display: none;
    }
    
    #banner2 {
      display: block;
      margin-bottom: 20px;
    }
    .title2>h1 {
      padding: 0 60px 0 60px;
    }

    .know-more {
      padding: 10px 60px 10px 60px;
      flex-wrap: wrap;
      margin-bottom: 0;
    }

    .center_know {
      border: none;
    }

    .know-more>div {
      padding: 0;
    }
    .to_top {
      display: flex;
      justify-content: flex-end;
      margin-top: -60px;
    }

    .fa-angle-up {
      color: #CE842D;
    }
  }
</style>
