<template>
    <div class="produto">
        <img class="banner-detailimg2" :src="require(`@/assets/images/banner_detail2.webp`)" />
        <h1 class="title">{{produto.name}}</h1>
        <div class="infos">
            <div class="fotos">
                <img class="banner-detailimg1" :src="require(`@/assets/images/banner_detail1.webp`)" />
                <div id="carouselTorta" class="carousel slide" data-bs-ride="carousel" data-bs-interval="2500">
                    <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselTorta" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Grande" v-show="isLoadedG"></button>
                        <button type="button" data-bs-target="#carouselTorta" data-bs-slide-to="1" aria-label="Fatia" v-show="isLoadedFat"></button>
                        <button type="button" data-bs-target="#carouselTorta" data-bs-slide-to="2" aria-label="Pequena" v-show="isLoadedP"></button>
                    </div>
                    <div class="carousel-inner">
                        <span v-if="produto.tipo=='torta'">
                            <div :class="carGAtt" class="active" v-show="isLoadedG">
                                <img v-bind:src="require(`@/assets/images/produtos/${produto.tipo}/${produto.prod_id}.webp`)" v-bind:alt="`${produto.name}`" class="card-img-top img-fluid" @load="onImgLoad('isLoadedG','carG')"> 
                            </div>
                            <div :class="carFatAtt" v-show="isLoadedFat">
                                <img v-bind:src="require(`@/assets/images/produtos/fatias/${produto.prod_id}.webp`)" v-bind:alt="`${produto.name}`" class="card-img-top img-fluid" @load="onImgLoad('isLoadedFat','carFat')"> 
                            </div>
                            <div :class="carPAtt" v-show="isLoadedP">
                                <img v-bind:src="require(`@/assets/images/produtos/torta/${produto.prod_id}_p.webp`)" v-bind:alt="`${produto.name}`" class="card-img-top img-fluid" @load="onImgLoad('isLoadedP','carP')"> 
                            </div>
                        </span>
                        <span v-if="produto.tipo=='bolo' || produto.tipo=='junino' || produto.tipo=='natal2'">
                            <div :class="carGAtt" class="active" v-show="isLoadedG">
                                <img v-bind:src="require(`@/assets/images/produtos/${produto.tipo}/${produto.prod_id}.webp`)" v-bind:alt="`${produto.name}`" class="card-img-top img-fluid" @load="onImgLoad('isLoadedG','carG')"> 
                            </div>
                        </span>
                    </div>
                </div>
            </div>
            <img class="banner-detailimg3" :src="require(`@/assets/images/banner_detail2.webp`)" />
            <div class="info_produtos">
                <div class="rating">
                    <div class="star-rating">
                        <span v-for="star in 5" :key="star"><font-awesome-icon icon="fa-regular fa-star" /></span>
                        <div class="star-rating__current" :style="{width: getRating+'%'}">
                            <span v-for="star in 5" :key="star" ><font-awesome-icon icon="fa-solid fa-star"  id="solidStar"/></span>
                        </div>
                    </div>  
                    <span class="votes">{{ produto.rating }} <small class="avaliacoes">({{ produto.votes }} AVALIAÇÕES)</small></span> 
                </div>
                <div class="desc">
                    <ShareNetwork class="share"
                        network="whatsapp"
                        :url="`https://www.dalena.com.br/produto/${produto.prod_id}`"
                        :title="`${produto.name.toUpperCase()}`"
                        :description="`${produto.desc}`"
                    >
                        <font-awesome-icon :icon="['fas', 'share-nodes']" />
                       <!-- <font-awesome-icon :icon="['fab', 'whatsapp']" />-->
                    </ShareNetwork>
                    <h1 class="nome_produto">{{produto.name.toUpperCase()}}</h1>
                    
                    <p class="desc_produto">{{produto.desc}}</p>
                    <p class="title_desc">TAMANHOS</p>
                    <div class="preco">
                        <span v-if="produto.tipo=='torta'">
                            <p><b>22cm | R${{ produto.preco_g }}</b> - <small>Serve de {{ rend_g[produto.rend_g] }} pessoas</small></p>
                            <p v-if="produto.preco_p!=0"><b>16cm | R${{ produto.preco_p }}</b> - <small>Serve de {{ rend_p[produto.rend_p] }} pessoas</small></p>
                            <p><b>Fatia | R${{ produto.preco_fat }}</b> - <small>Individual</small></p>
                        </span>
                        <span v-if="produto.tipo=='bolo' || produto.tipo=='junino' || produto.tipo=='natal2'">
                            <p><b><span v-if="produto.rend_p>0">Diâmetro: {{ produto.rend_p }}cm | </span>Peso aprox. {{ produto.rend_g }}g | R${{ produto.preco_g }}</b></p>
                        </span>
                    </div>
                </div>
            </div>
            <div class="reviews">
                <div class="title_reviews">
                    <p class="title_avaliacao">AVALIAÇÕES</p>
                    <button :disabled="submitted === true" type="button" ref="avalieBtn" class="btn btn-light btn-sm avalie" data-bs-toggle="modal" data-bs-target="#reviewModal">
                        AVALIE
                    </button>
                </div>
                <div class="reviews_cards">
                    <div v-for="review in reviews" :key="review.prod_id" class="card w-100">
                        <div class="card-body">
                            <p class="card-title">{{review.name}}</p>
                            <div class="avaliacao_review">
                                <div class="star-rating star-rating3">
                                    <span v-for="star in 5" :key="star"><font-awesome-icon icon="fa-regular fa-star" /></span>
                                    <div class="star-rating__current" :style="{width: getRatingReview(review.rating)+'%'}">
                                        <span v-for="star in 5" :key="star" ><font-awesome-icon icon="fa-solid fa-star"  id="solidStar"/></span>
                                    </div>
                                </div>
                                <span>· {{new Date(review.createdAt).toLocaleDateString("pt-BR", options)}}</span>
                            </div>
                            <p class="card-text">{{review.review}}</p>
                        </div>
                    </div> 
                    <button type="button" class="btn btn-light btn-sm view_more" @click="viewMore()" v-show="show">
                        Ver Mais
                    </button>
                </div>
            </div>
            <ReviewModal :id="produto.prod_id" :name="produto.name" @submitted="getSubmit" />
        </div>
    </div>
</template>

<script>
import ReviewModal from '@/components/ReviewModal.vue'
import list from '@/data/lista.json';
import { useHead } from '@unhead/vue'

export default {
    name: "Produto",
    setup() {
        useHead({
            title: 'Produto'
        })
    },
    components: {
        ReviewModal
    },
    data() {
        return {
            lista: list.list,
            API_URL: "https://6zk5h46ao2tkvb3emi4xosrcju0fvayv.lambda-url.us-east-2.on.aws/api",
            title: '',
            produto: {prod_id: '', name: ''},
            reviews: [],
            options: {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
            },
            count: 0,
            show: true,
            submitted: false,
            rate: this.$route.query.rate,
            rend_p: {
                p1: "6 a 8",
                p2: "8 a 10"
            },
            rend_g: {
                g1: "10 a 12",
                g2: "12 a 15",
                g3: "15 a 20"
            },
            isLoadedG: false,
            isLoadedFat: false,
            isLoadedP: false,
            carG: '',
            carFat: '',
            carP: ''
        }
    },
    created() {
        fetch(`${this.API_URL}/produtos/produto/${this.$route.params.produto}`)
        .then(async response => {
            const data = await response.json()

            this.produto = data;
            this.title = this.produto.tipo.toLowerCase().replace(/\b[a-z]/g, function(letter) {
                            return letter.toUpperCase();
                        });
        })
        .catch(error => {
            console.error('An error ocurred: ', error);
        });

        fetch(`${this.API_URL}/reviews/${this.$route.params.produto}/${this.count}`)
        .then(async response => {
            const data = await response.json()

            this.reviews = data;
            let test = data.length

            if (test<10) this.show = false;
        })
        .catch(error => {
            console.error('An error ocurred: ', error);
        });
    },
    mounted() {
        if (this.rate) {
            this.$refs.avalieBtn.click();
        }
        

    },
    methods: {
        getSubmit(res) {
            this.submitted = res;
        },
        getRatingReview(rating) {
            return ((rating/5)*100)
        },
        viewMore() {
            this.count++
            fetch(`${this.API_URL}/reviews/${this.$route.params.produto}/${this.count}`)
            .then(async response => {
                const data = await response.json();
                
                let test = data.length

                if (test<10) this.show = false;

                this.reviews = this.reviews.concat(data);
            })
            .catch(error => {
                console.error('An error ocurred: ', error);
            });
        },
        onImgLoad (imgName,car) {
            this[imgName] = true;
            this[car] = 'carousel-item';
            if (imgName='isLoadedFat' && this.isLoadedG == false) {
                this[car] = 'carousel-item active'
            }
        },
        login () {
            this.$gtag.event('login', { method: 'Google' })
        }
    },
    computed: {
        getRating: function() {
            return ((this.produto.rating/5)*100)
        },
        carGAtt: function() {
            return this.carG
        },
        carFatAtt: function() {
            return this.carFat
        },
        carPAtt: function() {
            return this.carP
        },
        produtoName: function() {
            return this.produto.name
        }
    }
}
</script>

<style lang="scss" scoped>
    @font-face {
        font-family: 'AutumnChant';
        src: local("Autumn Chant"),
        url(@/fonts/Autumn-Chant.otf) format("truetype");
    }
    @font-face {
        font-family: 'GoldplayAlt';
        src: local("GoldplayAlt"),
        url(@/fonts/GoldplayAlt-Medium.ttf) format("truetype");
    }
    @font-face {
        font-family: 'Lora';
        src: local("Lora"),
        url(@/fonts/Lora-Italic.ttf) format("truetype");
    }
    .produto {
        
        color: #77321C;
    }
    .banner-detailimg1,.banner-detailimg3 {
        display: none;
    }
    .banner-detailimg2 {
        display: block;
        width: 23%;
        position: absolute;
        top: 100px;
        left: 0;
        margin-left: 40px;
    }
    .title {
        margin: 60px 0 100px 0;
        text-align: center;
        font-family: 'Lora';
        color: #77321C;
        font-size: 32px;
        font-style: italic;
    }

    //estrelas
    .star-rating {
        display: inline-block;
        color: #CE842D;
        position: relative;
        font-size: 16px;
        vertical-align: middle;
    }

    .star-rating .star-rating__current {
        position: absolute;
        top: 0;
        overflow: hidden;
        white-space: nowrap;
    }

    .fa-star {
        margin-right: 3px;
    }
    .rating {
        display: flex;
        align-items: center;
    }
    .avaliacoes {
        color: grey;
        font-size: 12px;
    }

    // informações


    
    .infos{
        margin: 0 130px 0 130px;
    }
    .info_produtos{
        margin: 15px 0 20px 0;
    }
    .votes {
        font-family: 'GoldplayAlt';
        font-style: normal;
        font-size: 18px;
        color: #CE842D;
        margin-left: 5px;
    }
    .desc {
        margin-top: 15px;
        font-family: 'Lora';
        font-style: normal;
    }
    .desc_produto {
        font-size: 16px;
        padding-bottom: 20px;
        border-bottom: 1px solid #EDE2D1;
        margin-bottom: 25px;
    }
    .nome_produto {
        font-size: 24px;
        font-weight: bold;
    }
    .share {
        color: #77321C;
        font-size: 40px;
        float: right;
    }
    .title_desc {
        font-family: 'Lora';
        font-weight: bold;
        font-style: normal;
        margin-top: -5px;
        font-size: 16px;
    }
    .preco {
        font-family: 'Lora';
        font-size: 16px;
        line-height: 1;
        border-bottom: 1px solid #EDE2D1;
        padding-bottom: 15px;
    }

    .carousel-indicators>button {
        background: #77321C;
        height: 1px;
        width: 20px;
        border-radius: 30px;
        margin-right: 15px;
    }

    // reviews

    .title_reviews {
        display: flex;
        font-family: 'GoldplayAlt';
        font-style: normal;
        font-size: 20px;
        margin-bottom: 10px;        
    }

    .review_votes {
        margin-left: 5px;
        margin-bottom: 0;
    }
    .title_avaliacao {
        font-family: 'Lora';
        font-weight: bold;
        font-style: normal;
        font-size: 16px;
        margin: 0;
    }
    .btn.avalie {
        margin-left: auto;
        font-family: 'Lora';
        font-size: 14px;
        font-weight: bold;
        width: 35%;
        background-color: #F9B4A4;;
        color: #77321C;
        border-radius: 40px;
        --bs-btn-font-size: 1.2rem;
        --bs-btn-font-weight: bold;
    }
    .star-rating2 {
        color: black;
        font-size: 18px;
    }
    .reviews_cards {
       /* max-height: 600px;
        overflow-y: scroll;*/
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .card {
        background-color: transparent;
        border: 1px solid #EDE2D1;
        border-radius: 0;
        margin-bottom: 15px;
    }
    .card-title {
        font-family: 'GoldplayAlt';
        font-style: normal;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 0;
    }
    .star-rating3 {
        color: #CE842D;
        font-size: 14px;
    }
    .card-text {
        font-family: 'GoldplayAlt';
        font-style: normal;
        font-size: 14px;
    }
    .avaliacao_review {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 7px;
    }

    .avaliacao_review>span {
        font-family: 'GoldplayAlt';
        font-style: normal;
        color: grey;
        font-size: 12px;
    }
    .view_more {
        margin-bottom: 15px;
        width: 100%;
        color: #77321C;
        font-size: 16px;
        text-decoration: underline;
        background: transparent;
        border: none;
    }

    // MOBILE
    @media (max-width: 767px) {
        .banner-detailimg2,.title {
            display: none;
        }
        .banner-detailimg1 {
            display: block;
            width: 18%;
            position: absolute;
            left: 0;
            top: 70px;
        }
        .banner-detailimg3 {
            display: block;
            width: 23%;
            position: absolute;
            right: 0;
            margin-right: 26px;
        }
        .infos{
            margin: 0 26px 0 26px;
        }
        .carousel {
            margin-top: 26px;
        }
        .info_produtos{
            margin-top: 30px;
        }
        .star-rating2 {
            font-size: 14px;
        }
        .review_votes {
            margin-left: 0;
            font-size: 14px;
        }
    }
</style>
